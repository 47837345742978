<template>
     <a-modal
        :title="title"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @cancel="close"
        @ok="handleSubmit"
        destroyOnClose
        :width="642"
    >
      <a-form
        :form="form"
        v-bind="formItemLayout"
        v-if="visible"
    >
        <a-form-item label="账号">
            <a-input  v-decorator="[ 'username',{ initialValue: initname.username, rules: [{ required: true, message: '请填写分类名称' }] },]" placeholder="请输入链接" />
        </a-form-item>
        <a-form-item label="权限">
             <a-checkbox :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange">
                全部
            </a-checkbox>
            <a-checkbox-group v-decorator="[ 'subauth', {initialValue: initname.subauth, rules: [{required: true, message: '请选择权限'}]}]" :options="plainOptions" @change="onChange" />
        </a-form-item>
        <a-form-item label="密码" v-if="!data">
            <a-input type="password"  v-decorator="[ 'password', {rules: [{required: true, message: '请输入密码'}]}]" placeholder="请输入密码" />
        </a-form-item>
        <a-form-item label="确认密码" v-if="!data">
            <a-input type="password"  v-decorator="[ 'surepassword', {rules: [{required: true, message: '请输入确认密码'}]}]" placeholder="请输入确认密码" />
        </a-form-item>
      </a-form>
    </a-modal>
</template>
<script>
import * as http from '@/libs/examapi'
export default {
    name: 'addAccount',
    props: ['visible', 'data'],
    beforeCreate() {
        this.form = this.$form.createForm(this);
    },
    data(){
        return{
            formItemLayout: {
                labelCol: { span: 4 },
                wrapperCol: { span: 20 },
            },
            plainOptions :[{
                label:'展示营销',
                value:'1'
            },
            {
                label:'课程管理',
                value:'2'
            },
            {
                label:'学员管理',
                value:'3'
            },
            {
                label:'订单管理',
                value:'4'
            }],
            title: '',
            confirmLoading: false,
            checkAll: false,
            indeterminate: false,
            subauth: [],
            initname:{
                username:'',
                subauth:[]
            }
        }
    },
    watch: {
        visible(val){
            if(val){
                this.initname = {
                    username:'',
                    subauth: []
                }
                this.checkAll= false
            this.indeterminate= false
                if(this.data){
                    this.title = '编辑账号'
                    this.initname = {
                        username: this.data.username,
                        subauth: this.data.subauth.split(',')
                    }
                }else{
                    this.title = '新增账号'
                }
            }
        }
    },
    methods:{
        close(){
            this.$emit('close')
            setTimeout(()=>{
                this.checkAll = false
                this.indeterminate = false
                this.subauth = []
            },100)
        },
        handleSubmit(){
            this.form.validateFields((err, values) => {
                if(err) return;
               
                if(this.data){
                    this.confirmLoading = true
                    let {username, subauth} = values
                    let request = {...values, id:this.data.id}
                    http.edit_user(request).then(res=>{
                        this.confirmLoading = false
                        this.$emit('close')
                        this.$emit('update')
                    }).catch(error=>{
                        console.log(error)
                        this.confirmLoading = false
                    })
                }else{
                    let {password, surepassword, username, subauth} = values
                    if(password != surepassword){
                        this.$message.error('两次密码不同，请重新输入')
                        return 
                    }
                    let request = {username, password, subauth:subauth}
                    this.confirmLoading = true
                    http.add_user(request).then(res=>{
                         this.$emit('close')
                        this.$emit('update')
                        this.confirmLoading = false
                    }).catch(error=>{
                        console.log(error)
                        this.confirmLoading = false
                    })
                }
               
            })
        },
        onCheckAllChange(e){
            if(e.target.checked){
                this.form.setFieldsValue({'subauth':this.plainOptions.map(el=>el.value)})
            }else{

                this.form.setFieldsValue({'subauth': []})
            }
            Object.assign(this, {
                // subauth: e.target.checked ? this.plainOptions.map(el=>el.value) : [],
                indeterminate: false,
                checkAll: e.target.checked,
            });
        },
        onChange(subauth){
            this.subauth = subauth
            this.indeterminate = !!subauth.length && subauth.length < this.plainOptions.length;
            this.checkAll = subauth.length === this.plainOptions.length;
        }
    }
}
</script>